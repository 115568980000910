/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FlexColumn, FlexRowBetween, StyledBg } from '../style/projectComponents'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import toLocaleNumber from '../utils/toLocaleNumber'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import UserApi from '../service/user-api/UserApi'
import {
  IGetUserOperationsResponse,
  IGlobalDashboardItemsRequest,
  IGlobalDashboardItemsResponse,
  IStatusOperation,
} from '../service/user-api/models'
import ProfileStorage from '../storage/ProfileStorage/ProfileStorage'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { UserOperationsComponent } from '../components/DashboardComponents/UserOperationsComponent'
import { LineChartComponent } from '../components/DashboardComponents/LineChartComponent'
import { IGetTickersReq, IGetTickersRes, ITickerCategory } from '../service/bybit-api/models'
import BybitApi from '../service/bybit-api/BybitApi'
import InvestPoolApi from '../service/investpool-api/InvestPoolApi'
import { IGetAllInvestPoolsResponse } from '../service/investpool-api/models'
import {
  GetUserOperationsListQuery,
  OperationType,
  usePostApiUserGetUserDetails,
  usePostApiUserGetUserOperationsList,
} from '../service/api/generated'
import { currenciesList } from '../config/currenciesList'
import axios from 'axios'
import { UserStatisticsComponent } from '../components/DashboardComponents/UserStatisticsComponent'
import { UserBalanceComponent } from '../components/DashboardComponents/UserBalanceComponent'
import { InvitePartnersBlock } from '../components/DashboardComponents/InvitePartnersBlock'
import PageWrapper from '../components/global/PageWrapper'
import { PROJECT_NAME } from '../config/serverConstants'

const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 340px;
  max-width: 1100px;
  justify-content: start;
  margin-bottom: 0;
  border-radius: 8px;
  margin-top: 70px;
`

const StatsAndChartBlock = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
`
const UserStatsWrapper = styled(FlexRowBetween)`
  gap: 10px;
  max-width: 1000px;
  margin-bottom: 10px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const DashboardPage: React.FC = () => {
  const { t } = useTranslation()
  const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', { locale: ru })
  const ProfileStore = useContext(ProfileStorage)
  const walletId = ProfileStore.profile?.wallets?.find(it => it.currency === 'USDT')?.id
  const profileID = ProfileStore.profile?.id
  const getDashboardItemsQuery = useMutation((data: IGlobalDashboardItemsRequest) =>
    UserApi.getGlobalDashboardItems(data)
  )
  // const getOperationsItemsQuery = useMutation(
  //   (data: IGetUserOperationsRequest) => UserApi.getUserOperationsList(data)
  // );
  const getOperationsItemsQuery = usePostApiUserGetUserOperationsList()
  const getAllInvestPoolsQuery = useMutation(() => InvestPoolApi.getAllInvestPools())
  const [totalInvestProfit, setTotalInvestProfit] = useState(0)
  const [availableInvestPoolBalance, setAvailableInvestPoolBalance] = useState(0)
  const [currentInvestPoolBalance, setCurrentInvestPoolBalance] = useState(0)
  const [dashboardData, setDashboardData] = useState<
    IGlobalDashboardItemsResponse | { items?: { balanceDiff?: number }[] }
  >(null)
  const [types, setTypes] = useState<OperationType[]>([])
  const [investPools, setInvestPools] = useState<IGetAllInvestPoolsResponse>({
    items: [],
    totalCount: 0,
  })

  useEffect(() => {
    if (!!walletId) {
      const dashboardReq: IGlobalDashboardItemsRequest = {
        userId: profileID,
        takeLast: 200,
      }
      getDashboardItemsQuery.mutate(dashboardReq, {
        onSuccess: data => {
          setTotalInvestProfit(data?.totalProfit)
          setAvailableInvestPoolBalance(data?.availableInvestPoolsBalance)
          setCurrentInvestPoolBalance(data?.currentInvestPoolsBalance)
          setDashboardData({
            ...data,
            items: data.items.map((it, idx) => ({
              ...it,
              date: it.date,
              balance: it.balanceInUsd,
              balanceDiff:
                idx > 0
                  ? (it.balanceInUsd - data.items.at(idx - 1).balanceInUsd).toFixed(2)
                  : it.balanceInUsd,
            })),
          })
        },
      })
    }
  }, [!!walletId])

  const [page, setPage] = useState(0)
  const itemsPerPage = 10

  const [operationsData, setOperationsData] = useState<IGetUserOperationsResponse | any>({
    items: [],
    totalCount: 0,
  })
  useEffect(() => {
    if (!!profileID) {
      const operationsReq: GetUserOperationsListQuery = {
        statuses: Object.values(IStatusOperation),
        skip: page * itemsPerPage,
        take: itemsPerPage,
        userId: profileID,
        types,
      }
      getOperationsItemsQuery.mutate(
        { data: operationsReq },
        {
          onSuccess: data => {
            setOperationsData({
              ...data.data,
              items: data.data.items.map((it, idx) => ({
                ...it,
                balanceDiff: it.balanceDiff.toFixed(8),
              })),
            })
          },
        }
      )
      getAllInvestPoolsQuery.mutate(null, {
        onSuccess: data => {
          setInvestPools(data)
        },
      })
    }
  }, [profileID, page, types])

  const totalOperationsPages = useMemo(
    () => (operationsData?.totalCount ? Math.ceil(operationsData?.totalCount / itemsPerPage) : 1),
    [operationsData?.totalCount, itemsPerPage]
  )

  const { data: userDetailsData, mutate } = usePostApiUserGetUserDetails()
  const userBalance = userDetailsData?.data?.totalBalanceInUsd || 0

  useEffect(() => {
    profileID &&
      mutate({
        data: {
          userId: profileID,
        },
      })
  }, [profileID])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [tickers, setTickers] = useState<IGetTickersRes | null>(null)
  const spotTickersList = tickers?.result?.list || null
  const tickersReq: IGetTickersReq = {
    category: ITickerCategory.spot,
  }
  const getMarketTickersQuery = useQuery(
    ['market_spot_tickers'],
    () => BybitApi.getTickers(tickersReq),
    {
      onSuccess: data => {
        setTickers(data)
      },
      refetchInterval: 1500,
    }
  )
  const userBalanceIncludedPoolBalance = userBalance + currentInvestPoolBalance

  const [currenciesRates, setCurrenciesRates] = useState(null)
  const [activeCurrencyId, setActiveCurrencyId] = useState(0)
  const currencyCode = currenciesList[activeCurrencyId].code
  const currencySymbol = currencyCode?.toUpperCase()
  const activeCurrencyRate =
    (activeCurrencyId > 0 && currenciesRates && currenciesRates?.usd?.[currencyCode]) || 1
  useEffect(() => {
    const getCoingeckoCurrenciesUrl = `https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=${currenciesList.map((cur, id) => `${cur.code}${id < currenciesList.length - 1 ? '%2C' : ''}`).join('')}`
    // TODO: перенести валюты на наш бэк
    axios
      .get(getCoingeckoCurrenciesUrl)
      .then(response => {
        setCurrenciesRates(response.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const userTotalInNative = userBalance * activeCurrencyRate
  const totalInvestProfitNative = totalInvestProfit * activeCurrencyRate
  const currentInvestPoolBalanceNative = currentInvestPoolBalance * activeCurrencyRate
  const availableInvestPoolBalanceNative = availableInvestPoolBalance * activeCurrencyRate
  const dashboardDataNative = dashboardData?.items?.map(x => ({
    ...x,
    balance: x?.balance * activeCurrencyRate,
    balanceDiff: x?.balanceDiff * activeCurrencyRate,
    date: getDate(x?.date),
  }))

  return (
    <PageWrapper>
      <StyledBg>
        <InvitePartnersBlock />
        {PROJECT_NAME === 'Time' && (
          <>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://www.facebook.com/tr?id=1315103729754250&ev=PageView&noscript=1"
              alt=""
            />
            {/* <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://www.facebook.com/tr?id=1682975029270436&ev=PageView&noscript=1"
              alt=""
            /> */}
          </>
        )}
        <UserStatsWrapper>
          <UserBalanceComponent
            title={`${toLocaleNumber(userTotalInNative)}`}
            symbol={currencySymbol}
            description={t('dashboard.balance')}
            currenciesRates={currenciesRates}
            activeCurrencyId={activeCurrencyId}
            setActiveCurrencyId={setActiveCurrencyId}
          />
          <UserStatisticsComponent
            title={`${toLocaleNumber(totalInvestProfitNative)}`}
            symbol={currencySymbol}
            description={t('dashboard.total_invest_profit')}
          />
        </UserStatsWrapper>
        <UserStatsWrapper>
          <UserStatisticsComponent
            title={`${toLocaleNumber(currentInvestPoolBalanceNative)}`}
            symbol={currencySymbol}
            description={t('dashboard.current_invest_pool_balance')}
          />
          <UserStatisticsComponent
            title={`${toLocaleNumber(availableInvestPoolBalanceNative)}`}
            symbol={currencySymbol}
            description={t('dashboard.available_invest_pool_balance')}
          />
        </UserStatsWrapper>
        <StatsAndChartBlock>
          <BalanceChartWrapper>
            {dashboardData?.items?.length > 0 && (
              <LineChartComponent
                currency={currencySymbol}
                titleKey={[t('common.balanceDiff'), 'operationType']}
                valueKey={['balance', 'balanceDiff']}
                xAxisKey={'date'}
                yAxisKey={'balance'}
                data={dashboardDataNative}
              />
            )}
          </BalanceChartWrapper>
        </StatsAndChartBlock>
        <UserOperationsComponent
          operations={operationsData}
          isLoading={getOperationsItemsQuery.isLoading}
          totalPages={totalOperationsPages}
          pageId={page}
          setPageId={setPage}
          setTypes={setTypes}
        />
      </StyledBg>
    </PageWrapper>
  )
}

export default observer(DashboardPage)
