import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FlexRowBetween, FlexRow, FlexColumn, FlexColumnStart } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'

const Block = styled(FlexColumn)`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #8152b1; // TODO: gradient border
  background-color: ${({ theme }) => theme.colors.white};
  /* background:
    linear-gradient(#0a0a0a, #0a0a0a) padding-box,
    linear-gradient(to right, #000000 0%, #8152b1 102.04%) border-box;
  background: ${({ theme }) => theme.colors.white};
  -moz-border-image: -moz-linear-gradient(90deg, #000000 0%, #8152b1 102.04%);
  -webkit-border-image: -webkit-linear-gradient(90deg, #000000 0%, #8152b1 102.04%);
  border-image: linear-gradient(to right, #000000 0%, #8152b1 102.04%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2; */
  /* background:
    linear-gradient(#0a0a0a, #0a0a0a) padding-box,
    linear-gradient(to right, #000000 0%, #8152b1 102.04%) border-box; 
  border: 1px solid transparent;*/
  padding: 16px 20px;
  margin-bottom: 5px;
  @media screen and (max-width: 600px) {
    padding: 10px 2px;
  }
`

const Title = styled.h2`
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 600;
`

const OpenContentButton = styled(FlexRow)<{ checked: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  cursor: pointer;
  transition:
    --color-button-dark 1s cubic-bezier(0.075, 0.82, 0.165, 1),
    --color-button-dark-second 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  background: ${({ checked, theme }) =>
    !checked
      ? theme.colors.gray050
      : `linear-gradient(
    90deg,
    #ff8a00 0%,
  #d449f7 100%
  )`};

  img {
    width: 16px;
    height: 16px;
    filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
  }
`
const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 600;
`
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

interface FaqDropdownProps {
  title: string
  subtitle: string
  text: string
  Link: React.ReactNode
  isDefaultOpen: boolean
}

export const FaqDropdown: React.FC<FaqDropdownProps> = ({
  title,
  subtitle,
  text,
  Link,
  isDefaultOpen,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  return (
    <Block>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <Title>{title}</Title>
        <OpenContentButton onClick={() => setIsOpen(b => !b)} checked={isOpen}>
          <img
            src="/images/buttons/arrow-down.svg"
            alt="arrow-open-text-button"
            style={{ transform: isOpen && 'rotate(180deg)' }}
          />
        </OpenContentButton>
      </FlexRowBetween>
      {isOpen && (
        <FlexColumnStart style={{ width: '100%', marginTop: 18 }}>
          <VideoContainer>
            {/* <Subtitle>{subtitle}</Subtitle>
              <Text>{ext}</Text> */}
            {Link}
          </VideoContainer>
        </FlexColumnStart>
      )}
    </Block>
  )
}
