import React from 'react'
import styled from 'styled-components/macro'
import { AppTitleNormal, FlexColumn } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'
import { FaqDropdown } from './FaqDropdown'
import { is } from 'date-fns/locale'

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 30px;
  align-items: start;
`

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation()
  const faqList = [
    {
      title: 'How to start',
      isDefaultOpen: true,
      subtitle: 'Coming soon',
      text: 'We are working on it',
      Link: (
        <iframe
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/liviNKnxu8k?si=Hk3ejp-WXbCKHThk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ borderRadius: 8 }}
        ></iframe>
      ),
    },
  ]

  return (
    <Block>
      <AppTitleNormal style={{ marginBottom: 20 }}>{t('common.howItWorks')}</AppTitleNormal>
      {faqList?.map(i => (
        <FaqDropdown
          title={i.title}
          Link={i.Link}
          text={i.text}
          subtitle={i.subtitle}
          isDefaultOpen={i.isDefaultOpen}
        />
      ))}
    </Block>
  )
}
