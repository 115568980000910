import { PROJECT_NAME } from './serverConstants'

export const socialLinks = [
  {
    link:
      PROJECT_NAME === 'Time' ? 'https://t.me/+YPAutk0YDU85MDYy' : 'https://t.me/DefenceInvest ',
    image: '/images/social/tg.svg',
  },
  // {
  //   link: 'https://www.facebook.com/',
  //   image: '/images/social/fb.svg',
  // },
  {
    link: PROJECT_NAME === 'Time' ? 'https://x.com/TIMEINVESTMENTS' : 'https://x.com/',
    image: '/images/social/x.svg',
  },
  // {
  //   link: 'https://www.reddit.com',
  //   image: '/images/social/reddit.svg',
  // },
]
